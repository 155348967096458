import React from 'react';
import { MarketoFormModal } from '../Marketo/marketo-form-modal';
import { ButtonCTA } from './Button';
import { ContentfulButton as ContentfulButtonType } from '../../../contentful/content-types/elements/button';
import { makeStyledComponent } from '../Elements';
import { useLoggedIn } from '../../../common/hooks/useLoggedIn';

interface ContentfulButtonProps {
  className?: string;
  bladeContentTypeId?: string;
  button: ContentfulButtonType;
}

export const ContentfulButtonUnstyled: React.FC<ContentfulButtonProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {
    buttonWidth,
    buttonColor,
    modal,
    url,
    openInNewTab,
    text,
    standardText,
    loggedInUrl,
    buttonTextLoggedIn,
  } = props.button;

  const { className, bladeContentTypeId } = props;
  const isLoggedIn = useLoggedIn();

  const buttonText = standardText ? standardText : text;
  const buttonTextConditional = isLoggedIn && buttonTextLoggedIn ? buttonTextLoggedIn : buttonText;
  const urlConditional = isLoggedIn && loggedInUrl ? loggedInUrl : url;
  return (
    <>
      {modal && modal.marketoForm && (
        <MarketoFormModal
          onSuccessBehavior="show-message"
          open={isModalOpen}
          setOpen={setIsModalOpen}
          modal={modal}
        />
      )}
      <ButtonCTA
        className={className}
        buttonStyle={buttonWidth}
        href={urlConditional}
        openInNewTab={openInNewTab}
        buttonColor={buttonColor}
        bladeContentTypeId={bladeContentTypeId}
        onClick={(e) => {
          if (modal) {
            e.preventDefault();
            setIsModalOpen(true);
          }
        }}
      >
        {buttonTextConditional}
      </ButtonCTA>
    </>
  );
};

export const ContentfulButton = makeStyledComponent(ContentfulButtonUnstyled);

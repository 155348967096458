import React from 'react';
import { MarketoFormModal } from '../Marketo/marketo-form-modal';
import { A, makeStyledComponent } from '../Elements';
import { LocaleLink } from './LocaleLink';
import { ContentfulLink as ContentfulLinkType } from '../../../contentful/content-types/elements/link';

interface ContentfulLinkProps {
  className?: string;
  link: ContentfulLinkType;
}

const ContentfulLinkUnstyled: React.FC<ContentfulLinkProps> = ({ className, link }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { modal, url, standardText, text, openInNewTab } = link;
  return (
    <>
      {modal && modal.marketoForm && (
        <MarketoFormModal
          onSuccessBehavior="show-message"
          open={isModalOpen}
          setOpen={setIsModalOpen}
          modal={modal}
        />
      )}
      <LocaleLink
        openInNewTab={openInNewTab}
        display="inline-block"
        to={url ? url : '#'}
        className={`learn-more ${className}`}
        onClick={(e) => {
          if (modal) {
            e.preventDefault();
            setIsModalOpen(true);
          }
        }}
      >
        {standardText ? standardText : text}
      </LocaleLink>
    </>
  );
};

export const ContentfulLink = makeStyledComponent(ContentfulLinkUnstyled);

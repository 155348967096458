import * as React from 'react';
import { ContentfulPage } from '../../../contentful/content-types/page/page.interface';
import { isContentfulBladeHero, HeroBlade } from '../../common/refresh-blades/HeroBlade';
import {
  isContentfulBladeEnablement,
  EnablementBlade,
} from '../../common/refresh-blades/EnablementBlade';
import {
  isContentfulBladeMultipleFeatures,
  FeaturesBlade,
} from '../../common/refresh-blades/multipleFeaturesBlade/FeaturesBlade';
import {
  isContentfulBladeImageText,
  ImageTextBlade,
} from '../../common/refresh-blades/ImageTextBlade';
import TrustedByBlade, {
  isContentfulBladeTrustedBy,
} from '../../common/refresh-blades/TrustedByBlade';
import KeyFeaturesBlade, {
  isContentfulBladeKeyFeatures,
} from '../../common/refresh-blades/KeyFeaturesBlade';
import { isContentfulBladeQuote, QuoteBlade } from '../../common/refresh-blades/QuoteBlade';
import {
  isContentfulBladeFeatureList,
  BladeFeatureList,
} from '../../common/refresh-blades/FeatureListBlade';
import { BladeSubNav, isContentfulBladeSubNav } from '../../common/blades/BladeSubNav';
import {
  isContentfulBladePricingPlans,
  PricingPlansBlade,
} from '../../common/refresh-blades/PricingPlansBlade';
import {
  BladeDoubleContent,
  isDoubleContentBlade,
} from '../../common/refresh-blades/BladeDoubleContent';
import { BladeSignUpForm, isSignUpFormBlade } from '../../common/refresh-blades/BladeSignUpForm';
import {
  BladeEnterpriseForm,
  isEnterpriseFormBlade,
} from '../../common/refresh-blades/BladeEnterpriseForm';
import {
  BladeResourceGrid,
  isContentfulBladeResourceGrid,
} from '../../common/refresh-blades/BladeResourceGrid';
import { isPersonalizedBlade, Personalize } from '../../common/Personalizer/Personalize';

interface BladesProps {
  refreshStyles?: boolean;
  pagePrefix?: string;
  page: ContentfulPage;
}

export const TemplateBladeList: React.FC<BladesProps> = props => (
  <>
    {props.page.template?.relatedBlades &&
      props.page.template?.relatedBlades.map((blade, i) => {
        if (isContentfulBladeHero(blade)) {
          return <HeroBlade key={blade.contentfulId} blade={blade} page={props.page} />;
        } else if (isContentfulBladeEnablement(blade)) {
          return (
            <EnablementBlade page={props.page} key={`${blade.contentfulId}-${i}`} blade={blade} />
          );
        } else if (isContentfulBladeMultipleFeatures(blade)) {
          return <FeaturesBlade key={`${blade.contentfulId}-${i}`} blade={blade} />;
        } else if (isContentfulBladeImageText(blade)) {
          return <ImageTextBlade key={blade.contentfulId} blade={blade} />;
        } else if (isContentfulBladeTrustedBy(blade)) {
          return <TrustedByBlade key={blade.contentfulId} blade={blade} />;
        } else if (isContentfulBladeKeyFeatures(blade)) {
          return <KeyFeaturesBlade key={blade.contentfulId} blade={blade} />;
        } else if (isContentfulBladeQuote(blade)) {
          return <QuoteBlade key={blade.contentfulId} blade={blade} />;
        } else if (isContentfulBladeFeatureList(blade)) {
          return <BladeFeatureList key={`${blade.contentfulId}-${i}`} blade={blade} />;
        } else if (isContentfulBladeSubNav(blade)) {
          return (
            <BladeSubNav key={`blade-${i}`} blade={blade} pagePrefix={props.pagePrefix || ''} />
          );
        } else if (isContentfulBladePricingPlans(blade)) {
          return <PricingPlansBlade key={`${blade.contentfulId}-${i}`} blade={blade} />;
        } else if (isSignUpFormBlade(blade)) {
          return <BladeSignUpForm blade={blade} key={`${blade.contentfulId}-${i}`} />;
        } else if (isDoubleContentBlade(blade)) {
          return <BladeDoubleContent blade={blade} key={`${blade.contentfulId}-${i}`} />;
        } else if (isEnterpriseFormBlade(blade)) {
          return <BladeEnterpriseForm blade={blade} key={`${blade.contentfulId}-${i}`} />;
        } else if (isContentfulBladeResourceGrid(blade)) {
          return <BladeResourceGrid blade={blade} key={`${blade.contentfulId}-${i}`} />;
        } else if (isPersonalizedBlade(blade)) {
          return (
            <Personalize
              blade={blade}
              pagePrefix={props.pagePrefix || ''}
              page={props.page}
              key={blade.contentfulId}
            />
          );
        }
      })}
  </>
);

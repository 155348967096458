import React from 'react';
import { ContentfulRichTextColumnContentDataTarget } from '../../../contentful/content-types/blades/blade-richtext-columns';
import { EntryElementIcon } from '../../../contentful/content-types/blades/common/interfaces/common.interface';
import { ContentfulAssetFile } from '../ContentfulAssetFile/ContentfulAssetFile';
import { AssetFile } from '../ContentfulAssetFile/interfaces/asset-file.interface';
import { Span } from '../Elements';
import { getContentfulIcon, getLocaleEntry } from './richtext.utils';
import './richTextRenderer.style.scss';
import { ContentfulLink } from '../links/ContentfulLink';
import { ContentfulButton } from '../buttons/ContentfulButton';
import { ContentfulButton as ContentfulButtonType } from '../../../contentful/content-types/elements/button';
import { ContentfulLink as ContentfulLinkType } from '../../../contentful/content-types/elements/link';

interface RichTextEntryRendererProps {
  targetNode: ContentfulRichTextColumnContentDataTarget;
  type: 'inline' | 'block';
}

export const RichTextEntryRenderer: React.FC<RichTextEntryRendererProps> = ({
  targetNode,
  type,
}) => {
  const { entry_button, entry_elementIcon, entry_assetFile, entry_elementLink } = targetNode;

  if (entry_button) {
    const button = getLocaleEntry<ContentfulButtonType>(entry_button);
    if (button) {
      return <ContentfulButton marginTop={[6, 6, 6, 7]} button={button} />;
    }
  }
  if (entry_elementLink) {
    const link = getLocaleEntry<ContentfulLinkType>(entry_elementLink);
    if (link) {
      return <ContentfulLink link={link} />;
    }
  }
  if (entry_elementIcon) {
    const icon = getLocaleEntry<EntryElementIcon>(entry_elementIcon);
    if (icon) {
      const ContentfulIcon = getContentfulIcon(icon.icon);
      return (
        <Span
          marginBottom={type === 'block' ? 2 : 0}
          display={type === 'block' ? 'flex' : 'inline-flex'}
        >
          <ContentfulIcon size={icon.size} color={icon.color} />
        </Span>
      );
    }
  }
  if (entry_assetFile) {
    const asset = getLocaleEntry<AssetFile>(entry_assetFile);
    if (asset) {
      return <ContentfulAssetFile assetFile={asset} />;
    }
  }
  return null;
};

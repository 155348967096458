import React, { useState, useEffect } from 'react';
import { Div, Container, H3, Col, Span } from '../Elements';
import { ContentfulBladeAccordion } from '../../../contentful/content-types/blades/blade-accordion/blade-accordion.interface';
import { RelatedBlade } from '../../../contentful/blades';
import sectionPadding from '../BladePadding';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ReactMarkdown from 'react-markdown';
import { ContentfulRichTextElement } from '../../../contentful/content-types/blades/blade-richtext-columns';
import { RichTextRenderer } from '../RichTextRenderer';
import { richTextOptions } from '../RichTextRenderer/richTextOptions';
import { CFCheck } from '../CFIcons/CFCheck';

interface AccordionBladeProps {
  blade: ContentfulBladeAccordion;
}

export const getClassnameFromBackgroundColor = new Map([
  ['blue', 'bg-blue0'],
  ['white', 'bg-white'],
]);

export function isAccordionBlade(blade: RelatedBlade): blade is ContentfulBladeAccordion {
  return blade.contentTypeId === 'bladeAccordion';
}

export const AccordionBlade: React.FC<AccordionBladeProps> = ({ blade }) => {
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      setMouseDown(true);
    });
    document.addEventListener('keydown', (e) => {
      setMouseDown(false);
    });
  }, []);
  return (
    <Div
      {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}
      className={`${getClassnameFromBackgroundColor.get(blade.backgroundColor) || 'bg-white'}`}
    >
      <Container>
        <H3
          display={'block'}
          className="body-1"
          color="orange0"
          fontSize={2}
          fontWeight={6}
          marginBottom={1}
        >
          {blade?.sectionTitle}
        </H3>
        <H3 fontWeight={6} marginBottom={5}>
          {blade?.title}
        </H3>

        <Accordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          className={`guidelist pb6-l pb3`}
        >
          {blade?.accordionElements.map((item: ContentfulRichTextElement, index: number) => (
            <AccordionItem className="guidelist__item pv3" key={item.title}>
              <AccordionItemHeading>
                <AccordionItemButton
                  className={`pointer guidelist__button flex justify-between ${
                    mouseDown ? 'button-outline' : ''
                  }`}
                >
                  <Span className="body-1 fw6">{item?.title}</Span>
                  <Span className="dropdown-button-plans flex items-center"></Span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RichTextRenderer
                  options={richTextOptions({
                    listIcon: <CFCheck size={32} className="inline-flex" color="orange0" />,
                    PARAGRAPH_styleprops: {
                      margin: 0,
                      lineHeight: 'copy',
                      color: 'black',
                      marginTop: 2,
                      paddingRight: 5,
                    },
                  })}
                  richText={item?.column}
                />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </Div>
  );
};

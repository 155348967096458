import React from 'react';
import { useLocale } from '../../../common/hooks/useLocale';
import { Locale } from '../../../common/util/locales';
import { IconProps, Icons } from '../CFIcons/icon.interface';
import { iconMap } from '../CFIcons/IconMap';

export function getLocaleEntry<T extends { locale?: Locale }>(entries: T[]): T | null {
  const locale = useLocale();
  const localeEntry = entries?.find((entry) => entry.locale === locale);
  return localeEntry || null;
}

export const getContentfulIcon = (iconName: Icons): React.ComponentType<IconProps> => {
  const icon = iconMap[iconName];
  return icon;
};

import React, { ReactType } from 'react';
import { Div, Container, Row, Col, Ul, Li, Span, A, Img } from '../Elements';
import Text from '../Text/Text';
import { ContentfulBladeDoubleContent } from '../../../contentful/content-types/blades/blade-double-content';
import { RelatedBlade } from '../../../contentful/blades';
import { MarkdownBlock } from '../MarkdownBlock';
import { ButtonCTA } from '../buttons/Button';
import { ContentfulButton } from '../buttons/ContentfulButton';
import { ContentfulLink } from '../links/ContentfulLink';

interface DoubleContentBladeProps {
  blade: ContentfulBladeDoubleContent;
}

export function isDoubleContentBlade(blade: RelatedBlade): blade is ContentfulBladeDoubleContent {
  return blade.contentTypeId === 'bladeDoubleContent';
}

const markDownOptions: { [nodeType: string]: ReactType } = {
  paragraph: ({ children }) => (
    <Text marginBottom={[4, 4, 4, 2]} variant="body1">
      {children}
    </Text>
  ),
  list: ({ children }) => (
    <Ul marginBottom={[0, 0, 0, 5]} margin={0} padding={0} marginTop={3} paddingLeft={2}>
      {children}
    </Ul>
  ),
  link: ({ children, href }) => (
    <A href={href}>
      <Text variant="learnmore">{children}</Text>
    </A>
  ),
  listItem: ({ children }) => (
    <Li color="orange0">
      <Span color="black">{children}</Span>
    </Li>
  ),
  image: ({ children, src }) => (
    <Div marginBottom={[4, 4, 4, 6]}>
      <Img src={src} />
    </Div>
  ),
};

export const BladeDoubleContent: React.FC<DoubleContentBladeProps> = ({ blade }) => {
  return (
    <Div paddingTop={[4, 4, 4, 10]} paddingBottom={[7, 7, 7, 11]}>
      <Container>
        {blade.title ||
          (blade.longCopy && (
            <Div marginBottom={[5, 5, 5, 7]}>
              {blade.title && (
                <Row>
                  <Col lg={7}>
                    <Text variant="headline2">{blade.title}</Text>
                  </Col>
                </Row>
              )}
              {blade.longCopy && (
                <Row>
                  <Col lg={7}>
                    <MarkdownBlock source={blade.longCopy} renderers={markDownOptions} />
                  </Col>
                </Row>
              )}
            </Div>
          ))}

        <Row>
          <Col lg={5}>
            <Div>
              {blade.header1 && (
                <Text variant="headline3" marginBottom={3}>
                  {blade.header1}
                </Text>
              )}
              {blade.subheader1 && (
                <Text variant="headline4" marginBottom={3}>
                  {blade.subheader1}
                </Text>
              )}
              {blade.copy1 && <MarkdownBlock source={blade.copy1} renderers={markDownOptions} />}
            </Div>
            {blade.buttonText1 && blade.buttonUrl1 && (
              <Div marginTop={[4, 4, 4, 7]}>
                <ButtonCTA href={blade.buttonUrl1} buttonStyle="narrow" buttonColor="blue">
                  {blade.buttonText1}
                </ButtonCTA>
              </Div>
            )}
            {blade.button && blade.button[0] && (
              <Div marginTop={[4, 4, 4, 7]}>
                <ContentfulButton
                  bladeContentTypeId={blade.contentTypeId}
                  button={blade.button[0]}
                />
              </Div>
            )}
            {blade.link && blade.link[0] && (
              <Div marginBottom={[5, 7]}>
                <ContentfulLink link={blade.link[0]} />
              </Div>
            )}
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            {blade.header2 && (
              <Text variant="headline3" marginBottom={3}>
                {blade.header2}
              </Text>
            )}
            {blade.subheader2 && (
              <Text variant="headline4" marginBottom={3}>
                {blade.subheader2}
              </Text>
            )}
            {blade.copy2 && <MarkdownBlock source={blade.copy2} renderers={markDownOptions} />}
            {blade.buttonText2 && blade.buttonUrl2 && (
              <Div marginTop={[4, 4, 4, 7]}>
                <ButtonCTA href={blade.buttonUrl2} buttonStyle="narrow" buttonColor="blue">
                  {blade.buttonText2}
                </ButtonCTA>
              </Div>
            )}
            {blade.button && blade.button[1] && (
              <Div marginTop={[4, 4, 4, 7]}>
                <ContentfulButton
                  bladeContentTypeId={blade.contentTypeId}
                  button={blade.button[1]}
                />
              </Div>
            )}
            {blade.link && blade.link[1] && (
              <Div marginBottom={[5, 7]}>
                <ContentfulLink link={blade.link[1]} />
              </Div>
            )}
          </Col>
        </Row>
      </Container>
    </Div>
  );
};

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import loadable from '@loadable/component';
import { useMerchandizeData } from '../../../common/hooks/useMerchandizeData';
import { gtm } from '../../../common/scripts/gtm';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulPage } from '../../../contentful/content-types/page';
import {
  ContentfulPersonalizer,
  MRKAudienceSegmentFact,
  UserFeatures,
} from '../../../contentful/content-types/personalization';
import { Blade } from '../refresh-blades/RefreshBlades';
import { isRelatedBlade } from './personalize.checks';
import { isTrue } from './personalize.utils';

const Preview = loadable(() => import('./Preview'));

export function isPersonalizedBlade(blade: RelatedBlade): blade is ContentfulPersonalizer {
  return blade.contentTypeId === 'mrkPersonalizer';
}

interface PersonalizeProps {
  blade: ContentfulPersonalizer;
  pagePrefix?: string;
  page: ContentfulPage;
}

interface EntryProps {
  blade: RelatedBlade;
  pagePrefix?: string;
  page: ContentfulPage;
}

const RenderEntry: React.FC<EntryProps> = ({ page, pagePrefix, blade }) => {
  if (isRelatedBlade(blade)) {
    return <Blade page={page} index={0} pagePrefix={pagePrefix} blade={blade} />;
  }
  return <></>;
};

const metaDataQuery = graphql`
  query {
    site {
      siteMetadata {
        targetEnv
      }
    }
  }
`;

export const Personalize: React.FC<PersonalizeProps> = ({ blade, page, pagePrefix }) => {
  const userData = useMerchandizeData();
  const [previewData, setPreviewData] = React.useState<UserFeatures | null>(null);
  const [isPreview, setIsPreview] = React.useState<boolean>(false);
  const targetEnv = useStaticQuery(metaDataQuery)?.site?.siteMetadata?.targetEnv;

  const { personalisedEntries, controlEntry } = blade;

  const getFacts = (factsList: MRKAudienceSegmentFact[]): Partial<UserFeatures> => {
    const segmentFacts: Partial<UserFeatures> = factsList.reduce(
      (obj, item) => Object.assign(obj, { [item.fact]: item.value }),
      {} as Partial<UserFeatures>
    );
    return segmentFacts;
  };

  const userSegment = personalisedEntries.find((entry) => {
    const segmentFactsList = entry.audienceSegment.facts;
    const userBelongsToSegment = isTrue<Partial<UserFeatures>>(
      segmentFactsList,
      previewData || userData || {}
    );
    return userBelongsToSegment;
  });

  React.useEffect(() => {
    if (userData) {
      gtm({
        event: 'AudienceSegment',
        matchedSegment: userSegment?.audienceSegment.segmentName || 'DEFAULT',
        facts: userSegment?.audienceSegment.facts
          ? getFacts(userSegment.audienceSegment.facts)
          : null,
        userData,
      });
    }
  }, [userSegment, userData]);

  React.useEffect(() => {
    if (!isPreview && previewData) {
      setPreviewData(null);
    }
  }, [isPreview, previewData]);

  return (
    <>
      {targetEnv !== 'production' && (
        <Preview
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          previewData={previewData}
          setPreviewData={setPreviewData}
        />
      )}

      <RenderEntry
        page={page}
        pagePrefix={pagePrefix}
        blade={userSegment?.segmentEntry || controlEntry}
      />
    </>
  );
};

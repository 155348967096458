import * as React from 'react';
import { isContentfulBladeHero, HeroBlade } from './HeroBlade';
import { isContentfulBladeEnablement, EnablementBlade } from './EnablementBlade';
import { TrustedByBlade, isContentfulBladeTrustedBy } from './TrustedByBlade';
import {
  isContentfulBladeMultipleFeatures,
  FeaturesBlade,
} from './multipleFeaturesBlade/FeaturesBlade';
import { isContentfulBladeKeyFeatures, KeyFeaturesBlade } from './KeyFeaturesBlade';
import { isContentfulBladeImageText, ImageTextBlade } from './ImageTextBlade';
import { BladeFeatureList, isContentfulBladeFeatureList } from './FeatureListBlade';
import { isContentfulBladeQuote, QuoteBlade } from './QuoteBlade';
import { BladeSubNav, isContentfulBladeSubNav } from '../blades/BladeSubNav';
import { isRichTextGroupBlade, RichTextColumnBlade } from './RichTextColumnBlade';
import { ContentfulPage } from '../../../contentful/content-types/page/page.interface';
import { isContentfulBladePricingPlans, PricingPlansBlade } from './PricingPlansBlade';
import { BladeForm, isFormBlade } from './BladeForm';
import { isAccordionBlade, AccordionBlade } from './AccordionBlade';
import { BladeSignUpForm, isSignUpFormBlade } from './BladeSignUpForm';
import { BladeDoubleContent, isDoubleContentBlade } from './BladeDoubleContent';
import { BladeEnterpriseForm, isEnterpriseFormBlade } from './BladeEnterpriseForm';
import { BladeHtml, isHtmlBlade } from './HtmlBlade';
import { BladeResourceGrid, isContentfulBladeResourceGrid } from './BladeResourceGrid';
import { RelatedBlade } from '../../../contentful/blades';
import { isPersonalizedBlade, Personalize } from '../Personalizer/Personalize';
import { BladeTable, isContentfulBladeTable } from './BladeTable';

interface BladesProps {
  refreshStyles?: boolean;
  pagePrefix?: string;
  page: ContentfulPage;
}

interface BladeRenderedProps {
  blade: RelatedBlade;
  pagePrefix?: string;
  page: ContentfulPage;
  index: number;
}

export const Blade: React.FC<BladeRenderedProps> = ({ blade, pagePrefix, page, index: i }) => {
  if (isContentfulBladeHero(blade)) {
    return <HeroBlade key={`${blade.contentTypeId}-${i}`} blade={blade} page={page} />;
  } else if (isContentfulBladeEnablement(blade)) {
    return <EnablementBlade page={page} key={`${blade.contentfulId}-${i}`} blade={blade} />;
  } else if (isContentfulBladeMultipleFeatures(blade)) {
    return (
      <>
        <FeaturesBlade key={`${blade.contentTypeId}-${i}`} blade={blade} />
      </>
    );
  } else if (isContentfulBladeImageText(blade)) {
    return <ImageTextBlade key={`${blade.contentTypeId}-${i}`} blade={blade} />;
  } else if (isContentfulBladeTrustedBy(blade)) {
    return <TrustedByBlade key={`${blade.contentTypeId}-${i}`} blade={blade} />;
  } else if (isContentfulBladeKeyFeatures(blade)) {
    return <KeyFeaturesBlade key={`${blade.contentTypeId}-${i}`} blade={blade} />;
  } else if (isContentfulBladeQuote(blade)) {
    return <QuoteBlade key={`${blade.contentTypeId}-${i}`} blade={blade} />;
  } else if (isContentfulBladeFeatureList(blade)) {
    return <BladeFeatureList key={`${blade.contentTypeId}-${i}`} blade={blade} />;
  } else if (isContentfulBladeSubNav(blade)) {
    return <BladeSubNav key={`blade-${i}`} blade={blade} pagePrefix={pagePrefix || ''} />;
  } else if (isRichTextGroupBlade(blade)) {
    return <RichTextColumnBlade blade={blade} key={`${blade.contentTypeId}-${i}`} />;
  } else if (isFormBlade(blade)) {
    return <BladeForm blade={blade} key={`${blade.contentTypeId}-${i}`} />;
  } else if (isContentfulBladePricingPlans(blade)) {
    return <PricingPlansBlade key={`${blade.contentTypeId}-${i}`} blade={blade} />;
  } else if (isAccordionBlade(blade)) {
    return <AccordionBlade blade={blade} key={`${blade.contentTypeId}-${i}`} />;
  } else if (isSignUpFormBlade(blade)) {
    return <BladeSignUpForm blade={blade} key={`${blade.contentTypeId}-${i}`} />;
  } else if (isDoubleContentBlade(blade)) {
    return <BladeDoubleContent blade={blade} key={`${blade.contentTypeId}-${i}`} />;
  } else if (isEnterpriseFormBlade(blade)) {
    return <BladeEnterpriseForm blade={blade} key={`${blade.contentTypeId}-${i}`} />;
  } else if (isHtmlBlade(blade)) {
    return <BladeHtml blade={blade} key={blade.contentfulId} />;
  } else if (isContentfulBladeResourceGrid(blade)) {
    return <BladeResourceGrid blade={blade} key={`${blade.contentTypeId}-${i}`} />;
  } else if (isPersonalizedBlade(blade)) {
    return (
      <Personalize
        blade={blade}
        pagePrefix={pagePrefix || ''}
        page={page}
        key={blade.contentfulId}
      />
    );
  } else if (isContentfulBladeTable(blade)) {
    return <BladeTable blade={blade} key={blade.contentTypeId} />;
  }
  return null;

  // console.error('Invalid blade', blade);
  // throw new Error('Invalid blade type');
};

export const Blades: React.FC<BladesProps> = ({ pagePrefix, page }) => (
  <>
    {page.relatedBlades &&
      page?.relatedBlades?.map((blade, i) => (
        <Blade
          key={blade.contentTypeId}
          index={i}
          pagePrefix={pagePrefix || ''}
          page={page}
          blade={blade}
        />
      ))}
  </>
);
